<!--
	作者：jyl
	时间：2017-07-26
	描述：商户信息管理页面
-->
<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Pole_position_number')">
                <el-input
                  :maxlength="20"
                  size="12"
                  v-model.trim="formInline.barCode"
                  placeholder="请输入杆位号"
                ></el-input>
              </el-form-item>
              <!-- @change="areaChange" -->
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader @change="handleAreaChange" ref="cascader"></a-cascader>
              </el-form-item>

              <!-- <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.areaId"
                       filterable
                       placeholder="请选择">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="area.areaName"
                         :value="area.areaId"
                         :key="area.areaId"
                         v-for="area in areaList"></el-option>
            </el-select>
          </el-form-item> -->

              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所在道路">
                <my-component
                  ref="parkInput"
                  :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  :dataSource="1"
                  slaveRelations="0,2"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Pole_position_yourself')">
                <el-select v-model.trim="formInline.own">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="是" :value="0"></el-option>
                  <el-option label="否" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                v-if="$route.meta.authority.button.query"
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-refresh-right" plain @click="dele()"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.import || $route.meta.authority.button.export"
          ></div>
          <div
            class="col_box h44"
            v-if="$route.meta.authority.button.import || $route.meta.authority.button.export"
          >
            <div class="col_left">
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="imporT()"
                v-if="$route.meta.authority.button.import"
                >{{ $t('button.import') }}</el-button
              >
            </div>
            <div class="col_right mbd4">
              <el-button
                v-if="$route.meta.authority.button.export"
                type="info"
                icon="el-icon-upload2"
                @click="
                  page = 1;
                  exportFile();
                "
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <berth-detail
        :monitorEquipment="monitorEquipment"
        :videoEquipment="videoEquipment"
        ref="berthDetail"
      ></berth-detail>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%; font-size: 12px"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            :index="indexMethod"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="操作"
            width="80"
            align="center"
            v-if="$route.meta.authority.button.view"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <!-- v-if='$route.meta.authority.button.edit' -->
                  <el-dropdown-item command="a">查看</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-button type="text"  @click="$router.push({path: '/equipmentAE', query: scope.row})">修改</el-button>
              <el-button type="text" @click="deleteEquipment(scope.row)">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <el-dialog title="导入" :visible.sync="dialogVisible" width="600px">
        <div>
          1.请先获取批量添加设备的Excel模板将需要添加的信息填写后进行上传该Excel文件，
          上传非规范文件可能造成数据错误，<el-button type="text" @click="exportModel"
            >点击下载</el-button
          >模板文件。
        </div>
        <div>2.请选择上传文件，并上传。</div>
        <div style="margin-top: 20px">
          <el-upload
            class="upload-demo"
            ref="upload"
            :headers="header"
            :show-file-list="false"
            :on-success="uploadSuccess"
            :data="upData"
            action="/acb/2.0/bar/platform/import"
            :limit="1"
            :on-change="picChange"
            :file-list="fileList"
            :auto-upload="false"
          >
            <el-input
              v-model="fileName"
              style="width: 200px; float: left; margin-right: 5px"
            ></el-input>
            <el-button slot="trigger" type="primary">浏览...</el-button>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button type="primary" @click="submitUpload">上传</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import myComponent from "@/components/autocomplete/myautoComponent";
import berthDetail from "./Detail";
import { exportExcelNew } from "../../../common/js/public";
export default {
  name: "equipmentList",
  data() {
    return {
      upData: {
        "Session-Id": sessionStorage.token,
      },
      header: {
        "Session-Id": sessionStorage.token,
      },
      fileList: [],
      fileName: "",
      dialogVisible: false,
      areaId: "",
      areaList: [],
      monitorEquipment: [],
      videoEquipment: [],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      tenantList: [],
      tableCols: [
        {
          prop: "barCode",
          label: this.$t("list.Pole_position_number"),
          width: "",
        },
        {
          prop: "own",
          label: "是否自有杆",
          width: "",
          formatter: (cellValue) => {
            if (cellValue.own == 0) {
              return "是";
            } else {
              return "否";
            }
          },
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "parkName",
          label: "所在道路",
          width: ""
        },
        {
          prop: "equipmentVoList",
          label: "视频设备",
          width: "",
          formatter: (cellValue) => {
            if (cellValue.equipmentVoList) {
              let num = [];
              for (var item in cellValue.equipmentVoList) {
                num.push(cellValue.equipmentVoList[item].equipmentName);
              }
              return num.join(",");
            }
          },
        },
        {
          prop: "monitorEquipmentVoList",
          label: "监控设备",
          width: "",
          formatter: (cellValue) => {
            let num1 = [];
            if (cellValue.monitorEquipmentVoList) {
              for (var monit in cellValue.monitorEquipmentVoList) {
                num1.push(cellValue.monitorEquipmentVoList[monit].monitorEquipmentName);
              }
              return num1.join(" , ");
            }
          },
        },
        {
          prop: "operationName4",
          label: this.$t("list.latitude_and_longitude"),
          width: "",
          formatter: (cellValue) => {
            if (cellValue.longitude || cellValue.latitude) {
              return cellValue.longitude / 1000000 + "," + cellValue.latitude / 1000000;
            }
          },
        },
        {
          prop: "updatedTime",
          label: this.$t("list.update_time"),
          width: "170",
          formatter: (cellValue) => {
            if (cellValue.updatedTime) {
              let uptime = cellValue.updatedTime;
              var date = new Date(Number(uptime)); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
              var Y = date.getFullYear() + "-";
              var M =
                (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
                "-";
              var D = date.getDate() + " ";
              var h = date.getHours() + ":";
              var m = date.getMinutes() + ":";
              var s = date.getSeconds();
              return Y + M + D + h + m + s;
            } else {
              return "";
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        areaId: "",
        barCode: "",
        page: 1,
        pageSize: 15,
        operationId: "",
        parkId: "",
        own: "",
      },
    };
  },
  methods: {
    // 筛选条件-- 区域
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.formInline.areaId = areaId;
    },
    dele() {
      this.formInline.barCode = "";
      this.formInline.areaId = "";
      this.formInline.parkId = "";
      this.formInline.areaId = "";
      this.formInline.own = "";
      this.formInline.operationId = "";
      this.$refs.cascader.clear();
      this.$refs.parkInput.setValue();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        // this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        // this.formInline.parkName = '';
      }
    },
    // 下载模板
    exportModel() {
      let opt = {};
      opt = {
        scope: 1,
      };
      exportExcelNew("/acb/2.0/bar/downloadExcelTemplate", opt);
    },
    exportFile() {
      let opt = {};
      opt = {
        barCode: this.formInline.barCode,
        areaId: this.formInline.areaId,
        operationId: this.formInline.operationId,
        parkId: this.formInline.parkId,
        own: this.formInline.own,
      };
      // /acb/2.0/specialplate/export
      exportExcelNew("/acb/2.0/bar/platform/export", opt);
    },
    submitUpload() {
      if (this.picFlag) {
        this.$refs.upload.submit();
      } else {
        this.$alert("请选择需要上传的文件", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    picChange(file, row) {
      if (file.name && !file.response) {
        this.fileName = file.name;
        this.picFlag = true;
      } else {
        this.$refs.upload.clearFiles();
        this.fileName = "";
        this.picFlag = false;
      }
    },
    // 文件上传成功
    uploadSuccess(res) {
      if (res.state == 0) {
        this.$message({
          type: "info",
          message: "文件上传成功",
        });
        this.dialogVisible = false;
        this.pageNum = 1;
        this.searchData();
      } else {
        this.$alert(res.desc + " 请重新选择文件！！！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    imporT() {
      this.dialogVisible = true;
      this.picFlag = false;
      this.fileName = "";
      this.$refs.upload.clearFiles();
    },
    getAreaList() {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.areaList = res.value.areaList;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.berthDetail.getDetail(data);
        this.$refs.berthDetail.open();
      }
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    // 获取设备状态
    getOptions() {
      let url = "/acb/2.0/equipment/getAddOption";
      this.$axios
        .get(url, {
          data: this.formInline,
        })
        .then((res) => {
          // this.loading = false;
          if (res.state == 0) {
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      let url = "/acb/2.0/bar/platform/list";
      this.$axios
        .get(url, {
          data: {
            ...this.formInline,
            page: this.page,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {
    myComponent,
    berthDetail,
  },
  activated() {
    this.searchData();
  },
  created() {
    this.searchData();
  },
  mounted() {
    this.getOptions();
    this.getTenantList();
    this.getAreaList();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    // padding 22px 22px 0
    // margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 18px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
