<template>
    <div>
        <el-dialog
            title="查看杆位"
            :visible.sync="dialogVisible"
            width="500px"
            :show-close='false'
            custom-class='dialog'
            >
            <el-form ref="form" :model="formInline" label-width="120px" :rules='rules' style='padding:0'>
                <el-form-item label="杆位号:" prop='barCode'>
                    <span>{{ formInline.barCode }}</span>
                </el-form-item>
                <el-form-item label="经纬度:" prop=''>
                    <span>{{ this.lng }}</span>
                </el-form-item>
                <el-form-item label="是否自有杆:" prop='own'>
                    <span>{{ formInline.own === 0 ? '是' : '否' }}</span>
                </el-form-item>
                <el-form-item label="视频设备:" prop=''>
                    <div class='listStyleWrapper'>
                      {{videoList.equipmentName}}
                      <div v-for='v in videoList' class='listStyle' :key='v.equimentId'>{{v.equipmentName}}</div>
                    </div>
                </el-form-item>
                <el-form-item label="监控设备:" prop=''>
                    <div class='listStyleWrapper'>
                      {{ monitor.monitorEquipmentName }}
                      <div v-for='v in monitor' class='listStyle' :key='v.monitorEquipmentId'>{{v.monitorEquipmentName}}</div>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="dialogVisible = false">返回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
   data () {
      return {
        lng: '',
        barId: '',
        selectedVideo: [],
        selectedMonitor: [],
        videoList: [{equimentId: 123, equipmentName: '无'}],
        monitor: [{equimentId: 123, equipmentName: '无'}],
        dialogVisible: false,
        data2: [],
        formInline: {
          own: ''
        },
        rules: {
        }
      }
   },
   props: ['videoEquipment', 'monitorEquipment'],
   methods: {
      open () {
        this.dialogVisible = true;
        // this.videoList = [];
        // this.monitor = [];
        // this.formInline.barCode = '';
      },
      getDetail (data) {
        this.monitor = []
        this.videoList = []
        this.formInline = data
        let log = data.longitude || ''
        let lat = data.latitude || ''
        if (log || lat) {
          this.lng = log / 1000000 + ',' + lat / 1000000
        } else {
          this.lng = ''
        }
        if (data.equipmentVoList) {
          this.videoList = data.equipmentVoList
        }
        if (data.monitorEquipmentVoList) {
          this.monitor = data.monitorEquipmentVoList
        }
      }
   },
   created () {
   }
}
</script>
<style>
    .formWidth{
        width:221.5px;
    }

    .listStyle{
        text-align:center;
        border-bottom: 1px solid rgb(204, 204, 204);
        background: rgb(237, 241, 244);
    }
    .listStyleWrapper{
        width: 255px;
        height: 250px;
        background: #fefefe;
         border: 1px solid rgb(204, 204, 204);
        overflow: auto;
    }
</style>
